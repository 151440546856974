<template>
  <div
    class="header-bar"
    :class="[position, $route.name === 'exchange' ? 'transparent' : '']"
  >
    <div class="header-bar__content">
      <a
        v-if="to"
        class="header-bar__back"
        title="Go back to previous page"
        :to="to"
        @click.prevent="handlePreviousPage"
      >
        <svg
          viewBox="0 0 16 16"
          class="header-bar__arrow"
        >
          <path
            fill="currentColor"
            transform="translate(-1 -1)"
            fill-rule="evenodd"
            d="M15.936 8.364l-4.5-4.5a.899.899 0 1 0-1.272 1.272L13.127 8.1H2.7a.9.9 0 1 0 0 1.8h10.427l-2.963 2.964a.899.899 0 1 0 1.272 1.272l4.5-4.5a.899.899 0 0 0 0-1.272"
          />
        </svg>
        <span class="visually-hidden">Previous page</span>
      </a>
      <router-link
        :to="{name: 'lookup'}"
      >
        <div
          v-if="!$store.state.isEmbeddedPortal"
          class="header-bar__logo"
        >
          <p
            v-if="logo === '' || logo === null"
            class="header-bar__logo-name"
          >
            {{ shopName }}
          </p>
          <img
            v-else
            class="header-bar__logo-img"
            :src="logo"
            :alt="shopName"
          />
        </div>
      </router-link>
      <div class="header-bar__actions">
        <portal-target name="header-actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { navigateToOnstore } from '@/js/onstore';
import { experiments } from '@/js/constants/experiments';

export default {
  props: {
    logo: {
      type: String,
      required: false,
      default: null
    },
    shopName: {
      type: String,
      required: false,
      default: ''
    },
    referrer: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    to() {
      if (this.$route.name === 'exchange') {
        return null;
      }

      if (this.$route.meta.previous) {
        return this.$route.meta.previous;
      }

      return this.referrer ?? null;
    },
    position() {
      return this.$route.meta.navPosition ?? 'regular';
    },
    order() {
      return this.$store.getters.order;
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    hasCart() {
      return this.$store.state.cartToken;
    },
    onStoreEnabled() {
      return this.order.enabled.on_store_api === 'yes';
    },
    isFlowBEnabled() {
      return this.$store.getters.experimentVariation(experiments.RETURNS_PORTAL_FLOW_B_EXPERIMENT) === experiments.FLOW_B;
    },
    cameFromShopNow() {
      return this.$store.state.shopNowNavigation;
    }
  },
  methods: {
    handlePreviousPage() {
      const returnPath = this.isFlowBEnabled ? 'exchange' : 'review';
      if (this.$route.name === 'review') {
        if (this.cameFromShopNow && this.onStoreEnabled) {
          this.$store.commit('setShopNowNavigation', false);
          navigateToOnstore(returnPath);
          return;
        } else {
          this.$router.go(-1);
          return;
        }
      }

      if (this.$route.name === 'shop' && this.isFlowBEnabled) {
        return this.$router.push({ name: 'exchange' });
      }

      if (this.$route.name === 'shop' && this.$store.state.return.hasShopLaterOffer) {
        return this.$router.go(-1);
      }

      if (this.to) {
        this.$router.push(this.to);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.header-bar';

#{$block} {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: $black;
  width: 100%;
  height: 4.5rem;

  &.sticky {
    box-shadow: inset 0 -1px 0 0 $gray-medium, 0 1px 2px 0 rgba(60, 64, 67, 4%);

    @supports (position: sticky) {
      position: sticky;
      top: 0;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &__content {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    width: 100%;
    height: 100%;
    max-width: $max-site-width + 32px; // Site width + 2rem total padding
  }

  &__back {
    flex-shrink: 0;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    min-height: 2.5rem;
    transition: transform 0.3s ease;
    margin-left: -0.625rem; // Line up arrow to edge of content
    cursor: pointer;

    &:hover {
      transform: translate3d(-2px, 0, 0);
    }
  }

  &__arrow {
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(0.5turn);
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    padding: var(--spacing-200) 0;
  }

  &__logo-name {
    @include heading-theme;

    font-size: 1.875rem;
    color: var(--theme-heading-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo-img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  &__actions {
    flex-shrink: 0;
    margin-left: auto;
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    height: 3.75rem;

    &__content {
      padding: 0 0.75rem;
    }

    &__back {
      margin-left: 0;
    }

    &__logo-name {
      font-size: 1rem;
    }
  }
}
</style>
